export default {
  created() {
      this.updateBreakpoint()
  },

  data: function() {
    return {
      breakpoint: 5,
    };
  },

  methods: {
      updateBreakpoint() {
        switch (this.$vuetify.breakpoint.name) {
            case "xs":
              this.breakpoint = 1;
              break;
            case "sm":
              this.breakpoint = 2;
              break;
            case "md":
              this.breakpoint = 3;
              break;
            case "lg":
              this.breakpoint = 4;
              break;
            case "xl":
              this.breakpoint = 5;
              break;
          }
          console.log("Breakpoint:", this.breakpoint);
          }
  },

  watch: {
    "$vuetify.breakpoint.name": function() {
        this.updateBreakpoint();
    },
  },
};
