import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const ADMINISTRATOR = "Administrator"
const MANAGER = "Manager"
const BLOGGER = "Blogger"
const FOREMAN = "Foreman"
const OFFICE = "Office"

export default new Vuex.Store({
  state: {
    blogSelectedCategories: [],
    blogSelectedTags: [],
    blogSelectedItemsPerPage: 10,
    blogSelectedPage: 1,
    drawerOpen: false,
    loading: false,
    logSelectedUsers: [],
    logSelectedLevels: [],
    logSelectedLoggers: [],
    photoSelectedCategories: [],
    photoSelectedItemsPerPage: 10,
    photoSelectedPage: 1,
    returnUrl: '',
    user: {
      email: '',
      firstname: '',
      lastname: '',
      loggedIn: false,
      phone: '',
      userId: 0,
      username: '',
      role: 'User',
      token: '',
      expires: '',
    },
    dark: true,
  },

  getters: {
    isAdministrator: state => {
      var administrator = false
      if(state.user.loggedIn) {
        administrator = state.user.role === ADMINISTRATOR
      }
      return administrator
    },
    isManager: state => {
      var manager = false;
      if(state.user.loggedIn){
        manager = state.user.role === ADMINISTRATOR || state.user.role === MANAGER
      }
      return manager
    },
    isBlogger: state => {
      var blogger = false
      if(state.user.loggedIn) {
        blogger = state.user.role === ADMINISTRATOR || state.user.role === BLOGGER || state.user.role === MANAGER
      }
      return blogger
    },
    isForeman: state => {
      var foreman = false
      if(state.user.loggedIn) {
        foreman = state.user.role === ADMINISTRATOR || state.user.role === FOREMAN || state.user.role === MANAGER
      }
      return foreman
    },
    isOffice: state => {
      var office = false
      if(state.user.loggedIn) {
        office = state.user.role === ADMINISTRATOR || state.user.role === OFFICE || state.user.role === MANAGER
      }
      return office
    },
    
    getBlogSelectedCategories: state => state.blogSelectedCategories,
    getBlogSelectedTags: state => state.blogSelectedTags,
    getBlogSelectedItemsPerPage: state => state.blogSelectedItemsPerPage,
    getBlogSelectedPage: state => state.blogSelectedPage,
    getDrawerOpen: state => state.drawerOpen,
    getLoading: state => state.loading,
    getLogSelectedUsers: state => state.logSelectedUsers,
    getLogSelectedLevels: state => state.logSelectedLevels,
    getLogSelectedLoggers: state => state.logSelectedLoggers,
    getPhotoSelectedCategories: state => state.photoSelectedCategories,
    getPhotoSelectedItemsPerPage: state => state.photoSelectedItemsPerPage,
    getPhotoSelectedPage: state => state.photoSelectedPage,
    getUser: state => state.user,
    getReturnUrl: state => state.returnUrl,
    getDark: state => state.dark,
    getToken: state => state.user.token,
  },

  mutations: {
     
    loginUser(state, token) {
      state.user = {
        loggedIn: true,
        email: token.EMAIL,
        firstname: token.FIRSTNAME,
        lastname: token.LASTNAME,
        phone: token.PHONE,
        userId: parseInt(token.USER_ID),
        role: token.ACCESS_LEVEL,
        token: token.TOKEN,
        expires: token.EXPIRES,
      }
      console.log('User: ', state.user)
    },

    logoutUser(state) {
      state.user = {
        loggedIn: false,
        email: '',
        firstname: '',
        lastname: '',
        phone: '',
        userId: 0,
        role: '',
        token: '',
        expires: '',
      }
    },

    // Is the Navigation Drawer open or closed
    toggleDrawer(state) {
      state.drawerOpen = !state.drawerOpen
    },

    setBlogSelectedCategories(state, categories) {
      state.blogSelectedCategories = categories
    },

    setBlogSelectedTags(state, tags) {
      state.blogSelectedTags = tags
    },

    setBlogSelectedPage(state, page) {
      state.blogSelectedPage = page
    },

    setBlogSelectedItemsPerPage(state, item) {
      state.blogSelectedItemsPerPage = item
    },
    
    setDark(state, isDark) {
      state.dark = isDark
    },

    setLogSelectedUsers(state, users) {
      state.logSelectedUsers = users
    },
    setLogSelectedLevels(state, levels) {
      state.logSelectedLevels = levels
    },
    setLogSelectedLoggers(state, loggers) {
      state.logSelectedLoggers = loggers
    },

    setPhotoSelectedCategories(state, categories) {
      state.photoSelectedCategories = categories
    },

    setPhotoSelectedPage(state, page) {
      state.photoSelectedPage = page
    },

    setPhotoSelectedItemsPerPage(state, item) {
      state.photoSelectedItemsPerPage = item
    },

    isLoading(state) {
      state.loading = true
    },

    notLoading(state) {
      state.loading = false
    }
  },
  actions: {},
  modules: {}
})
