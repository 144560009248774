import axios from "axios";
import API from "./index";console.log(API.x)
import store from "@/_store/index";

export default {
  list() {
    console.log(API.x)
    store.commit("isLoading");
    const url = process.env.VUE_APP_BASE_URL + "Estimates";
    return axios.get(url, {})
    .then((response) => {
      return response.data;
    })
    .finally(() => {
        store.commit("notLoading");
    })
  },
  filter(categoryList, tagList) {
    store.commit("isLoading");
    var cats = '';
    var tags = '' ;
    categoryList.forEach(cat => {
      if(cats.length > 0) {
        cats = cats + ','
      }
      cats = cats + cat
    })
    tagList.forEach(tag => {
      if(tags.length > 0) {
        tags = tags + ','
      }
      tags = tags + tag
    })
    const url = process.env.VUE_APP_BASE_URL + "Estimates/Filter";
    const filter = {
      "categories": cats,
      "tags": tags,
    }
    return axios.post(url, filter)
    .then((response) => {
      return response.data;
    })
    .finally(() => {
        store.commit("notLoading");
    })
  },
  get(id) {
    store.commit("isLoading");
    const url = process.env.VUE_APP_BASE_URL + "Estimates/" + id;
    return axios.get(url, {})
    .then((response) => {
      return response.data;
    })
    .finally(() => {
        store.commit("notLoading");
    })
  },
  save(estimate) {
    if(estimate.id > 0) {
      return this.update(estimate)
    } else {
      return this.create(estimate)
    } 
  },
  create(estimate) {
    store.commit("isLoading");
    const url = process.env.VUE_APP_BASE_URL + "Estimates"
    return axios.post(url, estimate)
      .then(result => {
        return result
      })
      .catch(error => { 
        return error
      })
      .finally(() => {
        store.commit("notLoading");
      })
  },
  update(estimate) {
    store.commit("isLoading");
    const url = process.env.VUE_APP_BASE_URL + "Estimates/" + estimate.id
    return axios.put(url, estimate)
      .then(result => {
        return result
      })
      .catch(error => { 
        return error
      })
      .finally(() => {
        store.commit("notLoading");
      })
  },
  delete(id) {
    store.commit("isLoading");
    const url = process.env.VUE_APP_BASE_URL + "Estimates/" + id
    return axios.delete(url, {})
      .then(result => {
        return result
      })
      .catch(error => { 
        return error
      })
      .finally(() => {
        store.commit("notLoading");
      })
  }
};
