export default {
    getLocations: function() {
        return [
            {
                address: '3345 West 44th Avenue',
                city: 'Denver',
                hours: '',
                state: 'CO',                
                phone: '(303) 778-7663',
                to: '/location/denver',
                zipcode: '80211',
            },
            {
                address: '',
                city: 'Colorado Springs',
                hours: '',
                state: 'CO',
                phone: '(719) 390-7663',
                to: '/location/coloradosprings',
                zipcode: ''
            },
            {
                address: '',
                city: 'Pueblo',
                hours: '',
                state: 'CO',
                phone: '(719) 544-5544',
                to: '/location/pueblo',
                zipcode: '',
            },
            {
                address: '15235 Peyton Highway',
                city: 'Peyton',
                hours: 'Monday - Friday, 8am - 4pm',
                state: 'CO',
                phone: '(719) 390-7663',
                to: '/location/peyton',
            },
        ]
    },
}