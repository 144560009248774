import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },

  theme: {
    dark: true,
    themes: {
      light: {
        primary: colors.blue,
        secondary: '#00008B',
        accent: '#8B008B',
        error: colors.red,
        warning: colors.yellow,
        info: colors.brown,
        success: colors.green,
        anchor: colors.lime,
        menu: '#eeeeee',
      },
      dark: {
        primary: colors.amber,
        secondary: colors.green,
        accent: colors.orange,
        error: colors.red,
        warning: colors.yellow,
        info: colors.brown,
        success: colors.green,
        menu: '#000000',
      }
    },
  },
});
