<template>
  <v-row class='social-row'>
    <v-spacer></v-spacer>
    <div v-for="social in socialButtons" v-bind:key="social.key" :class="social.class">
      <a :href="social.href" target="_blank">
        <v-img
          :alt="social.alt"
          :src="social.image"
          :min-height="social.height"
          :min-width="social.width"
          :max-height="social.height"
          :max-width="social.width"
          :height="social.height"
          :width="social.width"
        >
        </v-img>
      </a>
    </div>
  </v-row>
</template>

<script>
export default {
  name: "rw-social-buttons",

  created(){
    this.socialButtons.push({
        id: 1,
        alt: "BBB",
        class: "bbb",
        height: 50,
        width: 172,
        href: "https://www.bbb.org/us/co/peyton/profile/roofing-contractors/aquaduct-roof-gutters-0785-7499070",
        image: this.bbb,
        source: "bbb",
    })
    this.socialButtons.push({
        id: 2,
        alt: "Facebook",
        class: "icon",
        height: 36,
        width: 36,
        href: "https://www.facebook.com/aquaductroofing",
        image: this.facebook,
        source: "facebook",
    })
    this.socialButtons.push({
        id: 3,
        alt: "Twitter",
        class: "icon",
        height: 36,
        width: 36,
        href: "https://twitter.com/AquaDuctSystems",
        image: this.twitter,
        source: "twitter",
    })
    this.socialButtons.push({
        id: 4,
        alt: "LinkedIn",
        class: "icon",
        height: 36,
        width: 36,
        href: "https://www.linkedin.com/company/aquaduct-roof-&-gutters/",
        image: this.linkedin,
        source: "linkedin",
    })
    this.socialButtons.push({
        id: 5,
        alt: "UTube",
        class: "icon",
        height: 36,
        width: 36,
        href: "https://www.youtube.com/user/AquaDuctSystems",
        image: this.youTube,
        source: "youTube",
    })
  },

  data: () => ({
    bbb: require("@/assets/bbb.png"),
    facebook: require("@/assets/facebook.png"),
    googlePlus: require("@/assets/google-plus.png"),
    linkedin: require("@/assets/linkedin.png"),
    socialButtons: [],
    twitter: require("@/assets/twitter.png"),
    yelp: require("@/assets/yelp.png"),
    youTube: require("@/assets/you-tube.png"),
  }),
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.bbb {
  margin-top: 5px;
}
.icon {
  margin-left: 5px;
  margin-top: 10px;
}
.button {
  margin: 2px;
}
.button:hover {
  cursor: pointer;
}
.ransford {
  margin-left: 20px;
  margin-top: 12px
}
.social-row {
  margin-top: -20px;
  height: 50px;
}
</style>
