<template>
  <div v-if="loggedIn">
    <v-list-item two-line :class="miniVariant && 'px-0'">
      <v-list-item-icon>
        <v-icon>mdi-account</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ user.firstname + " " + user.lastname }}</v-list-item-title>
        <v-list-item-subtitle>{{ user.role }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import store from "@/_store/index";
import loggedInMixin from '../../mixins/loggedin-mixin'

export default {
  name: "account-item",

  mixins: [loggedInMixin],

  data: () => ({
    miniVariant: false,
    user: {
      email: "",
      firstname: "",
      lastname: "",
      memberId: 0,
      phone: "",
      userId: 0,
      role: "",
    },
  }),

  store,
  watch: {
    "$store.state.user": function() {
      this.user = store.getters.getUser;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

