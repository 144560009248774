<template>
  <v-footer absolute app class="font-weight-medium">
    <v-container class="container">
      <v-row>
        <v-spacer></v-spacer>
          <a
            href="https://api.gethearth.com/partners/aquaduct-roof-gutters?utm_campaign=28793&utm_content=zero_percent&utm_medium=contractor-website&utm_source=contractor&utm_term=700x110"
            target="_blank"
          >
            <img
              src="https://api.gethearth.com/contractor_images/aquaduct-roof-gutters/banner.jpg?size_id=700x110&color=zero_percent"
              alt="home_improvement 700x110"
              style="height: 110px; width: 700px"
            />
          </a>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-col
          class="text-center"
          cols="12"
          lg="2"
          md="2"
          sm="4"
          v-for="place in locations"
          v-bind:key="place.to"
        >
          <location
            :to="place.to"
            :city="place.city"
            :phone="place.phone"
          ></location>
        </v-col>
        <v-col class="text-center" cols="12" lg="2" md="2" sm="4">
          <strong>
            <v-btn text @click="onSiteMap">Site Map</v-btn>
            <br />
          </strong>
        </v-col>
      </v-row>
      <v-row class="button-row">
        <v-col cols="12" xl="4" lg="4" md="0" sm="0" v-if="breakpoint > 3">
          <h2 class="water">Putting Water In Its Place</h2>
        </v-col>
        <v-col cols="12" xl="8" lg="8" md="12" sm="12" v-if="breakpoint > 0">
          <socialbuttons class="social"></socialbuttons>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import breakpointMixin from "../../mixins/breakpoint-mixin";
import location from "../controls/location-ctrl";
import locationService from "@/_services/location-service";
import socialbuttons from "./social-buttons.vue";
import router from "@/_router/index.js";

export default {
  name: "app-footer",

  components: {
    location,
    socialbuttons,
  },

  created() {
    this.locations = locationService.getLocations();
  },

  data: () => ({
    locations: [],
    mode: process.env.VUE_APP_TITLE,
  }),

  mixins: [breakpointMixin],

  methods: {
    getRoutesList(routes, pre) {
      return routes.reduce((array, route) => {
        const path = `${pre}${route.path}`;

        if (
          route.path !== "*" &&
          typeof route.excludeFromSiteMap === "undefined"
        ) {
          array.push(path);
        } else {
          console.log("Excluding: ", route.path);
        }

        if (route.children) {
          array.push(...this.getRoutesList(route.children, `${path}/`));
        }

        return array;
      }, []);
    },

    onSiteMap() {
      console.log("onSiteMap()");
      const list = this.getRoutesList(
        router.options.routes,
        "https://aquaductroofing.com"
      )
        .map((route) => `<url><loc>${route}</loc></url>`)
        .join("\r\n");
      const siteMap = `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
        ${list}
        </urlset>`;

      var blob = new Blob([siteMap], { type: "application/xml" });
      var a = document.createElement("a");
      a.download = "sitemap.xml";
      a.href = URL.createObjectURL(blob);
      a.dataset.downloadurl = ["application/xml", a.download, a.href].join(":");
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(function () {
        URL.revokeObjectURL(a.href);
      }, 1500);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/variables.scss";
.bbb {
  padding-top: 2px;
}
.button-row {
  background-color: darkgrey;
  height: 60px;
}
.container {
  width: 100%;
}

.col-md-4 {
  padding: 1px;
}
.ransford {
  padding-top: 10px;
}
.social {
  margin-top: -10px;
  margin-right: 20px;
}
.text-center {
  font-size: 0.9rem;
}
.water {
  color: $light-color;
}
@media (min-width: 1264px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 960px) {
  .container {
    width: 100%;
  }
}
</style>
