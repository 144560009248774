<template>
  <v-bottom-navigation
    color='primary'
    v-if="display"
    hide-on-scroll
    style="height: 55px;"
    app
  >
    <v-container color="primary">
      <v-row>
        <v-btn icon x-large class="button" @click="onMenu"><div class="menu-item">Menu</div>
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon x-large class="button" to="/"><div class="menu-item">Home</div>
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon x-large class="button" to="/contact"><div class="menu-item">Contact Us</div>
          <v-icon>mdi-email</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon x-large class="button" to="/photo"><div class="menu-item">Photos</div>
          <v-icon>mdi-image</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon x-large class="button" to="/blog"><div class="menu-item">Blog</div>
          <v-icon>mdi-blogger</v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </v-bottom-navigation>
</template>

<script>
import store from "@/_store/index";

export default {
  name: "app-bottom-nav",
  components: {},
  computed: {
    display() {
      var rc = false;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          rc = true;
          break;
        case "sm":
          rc = true;
          break;
        case "md":
          rc = false;
          break;
        case "lg":
          rc = false;
          break;
        case "xl":
          rc = false;
          break;
      }
      return rc;
    },
  },

  methods: {
    onMenu() {
      store.commit("toggleDrawer");
    },
  },
  store: store,
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.button {
  margin-top: -10px;
}
</style>
