import store from "@/_store/index";

export default {
  data: function() {
    return {
      loggedIn: false,
    };
  },

  store,
  watch: {
    "$store.state.user": function() {
      this.loggedIn = store.getters.getUser.userId > 0;
    },
  },
};
