import axios from "axios";
import store from "@/_store/index";

const x = "";

export default {
  x: x,
};

axios.interceptors.request.use(function(config) {
  const token = store.getters.getToken;
  if (token) {
    // console.log("Applying ApiKey and Token")
    config.headers.common['ApiKey'] = "34134ee7-ecc9-4c63-911b-3e7e7b2e0394";
    config.headers.common['Token'] = token;
  } 
  // else {
  //   console.log("Not applying ApiKey and Token")
  // }

  return config;
});
