<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <!-- <systembar></systembar> -->
    <appdrawer></appdrawer>
    <appbar></appbar>
    <v-main>
      <v-container class="pages">
        <div v-if="isLoading()">
          <v-progress-linear
            indeterminate
          ></v-progress-linear>
        </div>
        <router-view :key="$route.path"></router-view>
      </v-container>
    </v-main>

    <appfooter></appfooter>
    <bottonnav></bottonnav> 
    </v-app>
</template>

<script>
import appbar from "./components/app/appbar";
import appdrawer from "./components/app/drawer";
import appfooter from "./components/app/footer";

import bottonnav from "./components/app/bottom";
import store from "@/_store/index";
// import systembar from "./components/app/system-bar";
import Static from "@/views/static-page.vue";
import router from "@/_router/index.js";
import PageService from "@/_services/api/page-service.js";

export default {
  name: "App",

  components: {
    appbar,
    appdrawer,
    appfooter,
    bottonnav,
    // appdrawer,
    // systembar,
  },

  created() {
    console.log("App.created()");
    console.log("BaseUrl: ", process.env.VUE_APP_BASE_URL)
    PageService.list().then((list) => {
      let dynamicRoutes = [];
      for (var i = 0; i < list.length; i++) {
        const x = list[i];
        dynamicRoutes.push({
          path: x.path,
          meta: {
            authorize: [],
            title: x.title + " - AquaDuct Roof and Gutters",
            metaTags: [
              {
                property: "og:title",
                content: x.title + " - AquaDuct Roof and Gutters",
              },
              {
                property: "og:description",
                content: x.title,
              },
            ],
          },
          component: Static,
        });
      }
      router.addRoutes(dynamicRoutes);
    });
  },

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    display() {
      var rc = false;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          rc = false;
          break;
        case "sm":
          rc = false;
          break;
        case "md":
          rc = true;
          break;
        case "lg":
          rc = true;
          break;
        case "xl":
          rc = true;
          break;
      }
      return rc;
    },
  },

  data: () => ({
    expandOnHover: true,
    miniVariant: false,
    permanent: false,
  }),

  methods: {
    isLoading() {
      return this.$store.getters.getLoading;
    },
  },
  store,
  router,
};
</script>
<style>
</style>
