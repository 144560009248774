<template>
  <v-container>
    <h1>{{ page.name }}</h1>
    <v-row>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12">
        <v-row
          v-for="section in sections"
          v-bind:key="section.id"
          :class="section.backgroundColor"
        >
          <v-col cols="12">
            <div v-html="section.markup" :class="section.backgroundColor"></div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="4" sm="12">
        <estimate></estimate>
        <!-- <guild></guild> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageService from "@/_services/api/page-service.js";
import estimate from "../components/controls/estimate-ctrl";
// import guild from "../components/controls/GuildQuality";

export default {
  components: {
    estimate,
    // guild
  },

  computed: {
  },

  created() {
    console.log(this.$route)
    PageService.get(this.$route.path).then(result => {
      PageService.getId(result.id).then(page => {
        this.page = page
        console.log(page)
        this.sections = this.page.sections
        this.sections.sort(this.compare)
      })
    });
  },

  data: () => ({
    page: {
      id: 0,
      name: "",
      path: "",
      title: "",
    },
    sections: []
  }),

  methods: {
    compare(a, b) {
      if(a.order < b.order) {
        return -1
      }
      if(a.order > b.order) {
        return 1
      }
      return 0
    }
  },

  mounted() {
    console.log('mounted()')
  }
};
</script>

<style lang="scss" scoped>
.lightslategrey {
  background-color: lightslategray;
  margin-bottom: 20px;
}
.slategrey {
  background-color: slategray;
  margin-bottom: 20px;
}
.grey {
  background-color: grey;
  margin-bottom: 20px;
}
</style>