<template>
  <v-snackbar
    bottom
    v-model="display"
    :color="color"
    :timeout="timeout"
    vertical
  >
    <div v-for="msg in messages" v-bind:key="msg">{{ msg }} <br /></div>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn outlined text @click="alertClosed">
        Close
      </v-btn>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  name: "status-ctrl",
  props: ["successMessage"],

  data: () => ({
    display: false,
    color: "success",
    messages: [],
    statusCode: 200,
    timeout: 5000,
  }),

  methods: {
    alertClosed() {
      this.display = false;
    },

    displayResult(result) {
      this.statusCode = result.status;
      this.display = true;
      this.color = this.statusCode === 200 ? "success" : "error";
      this.messages = []
      if(result.status == 200) {
        this.messages.push(result.statusText)
      } else {
        this.messages = result.errors;
      }
    },

    displayActionResult(result) {
      console.log("ActionResult:", result);
      this.messages = [];
      this.display = true;
      if (result.status) {
        this.statusCode = result.status;
        if (this.statusCode === 200 || this.statusCode === 201) {
          this.messages.push(this.successMessage);
          this.color = "success";
        }
      } else if (result.response) {
        this.color = "error";
        this.statusCode = result.response.status;
        if (result.response.status === 400) {
          this.messages.push(result.response.data.title);
          for (var key in result.response.data.errors) {
            this.messages.push(result.response.data.errors[key]);
          }
        } else if (result.response.status === 401) {
          this.messages.push("Unathorized");
        } else if (result.response.status === 404) {
          this.messages.push(result.response.data.title);
        } else {
          this.messages.push("Unknown error");
        }
      } else {
        this.statusCode = 500;
        this.messages.push(
          "Error connecting to server, if this problem persists contact your administrator"
        );
        this.color = "error";
      }
    },
  },

  watch: {
    display() {
      if (!this.display) {
        this.$emit("closed", this.statusCode);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-btn {
  background-color: $light-background-color;
  color: $light-color;
}
.theme--dark.v-btn {
  background-color: $dark-background-color;
  color: $dark-color;
}</style>
