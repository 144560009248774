<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    temporary
    width="325"
  >
    <v-list nav dense>
      <account></account>
      <div v-for="item in menuItems" v-bind:key="item.text">
        <v-list dense v-if="item.subMenuItems.length === 0">
          <v-list-item :to="item.to">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>{{ item.text }}
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <v-list-group v-else no-action :value="false">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>{{ item.text }}
            </v-list-item-icon>
          </template>
          <v-list-item
            v-for="subMenuItem in item.subMenuItems"
            v-bind:key="subMenuItem.text"
            :to="subMenuItem.to"
          >
            <v-list-item-icon>
              <v-icon>{{ subMenuItem.icon }}</v-icon>{{ subMenuItem.text }}
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </div>
      <v-list-item color="secondary" @click="onToggleTheme">
        <v-list-item-icon>
          <v-icon>mdi-theme-light-dark</v-icon> {{theme}}
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import account from "./account-item";
import store from "@/_store/index";
import menuService from "@/_services/menu-service.js";
import router from '@/_router/index'
import loggedInMixin from "../../mixins/loggedin-mixin";

export default {
  name: "app-drawer",

  mixins: [loggedInMixin],

  components: {
    account,
  },
  computed: {},

  data: () => ({
    drawer: false,
    group: null,
    menuItems: [],
    lastBreak: 0,
    theme: "Light Theme",
    user: {},
  }),

  mounted() {
    this.lastBreak = this.getBreakPoint();
    this.filterMenuItems();
  },

  methods: {
    onClose() {
      store.commit("toggleDrawer");
    },
    getBreakPoint() {
      var rc = 0;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          rc = 1;
          break;
        case "sm":
          rc = 2;
          break;
        case "md":
          rc = 3;
          break;
        case "lg":
          rc = 4;
          break;
        case "xl":
          rc = 5;
          break;
      }
      return rc;
    },

    onMenuItem(to) {
      router.push(to);
    },

    onToggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      store.commit("setDark", this.$vuetify.theme.dark);
      this.theme = this.$vuetify.theme.dark ? "Light Theme" : "Dark Theme"
    },

    filterMenuItems() {
      var rawMenuItems = menuService.getMenu();

      this.menuItems = [];
      for (var i = 0; i < rawMenuItems.length; i++) {
        var menuItem = rawMenuItems[i];
        var subMenuItems = [];
        var rawSubMenuItems = rawMenuItems[i].subMenuItems;
        for (var j = 0; j < rawSubMenuItems.length; j++) {
          var subMenuItem = rawSubMenuItems[j];
          if (subMenuItem.rule) {
            
            if (subMenuItem.rule === "loggedIn" && this.loggedIn) {
              subMenuItems.push(subMenuItem);
            }

            if (subMenuItem.rule === "loggedOut" && !this.loggedIn) {
              subMenuItems.push(subMenuItem);
            }
            
            if(subMenuItem.rule === "administrator" && this.user.role === "Administrator") {
              subMenuItems.push(subMenuItem)
            }

            if(subMenuItem.rule === "manager" && (this.user.role === "Administrator" || this.user.role === "Manager")) {
              subMenuItems.push(subMenuItem)
            }

          } else {
            subMenuItems.push(subMenuItem);
          }
        }
        menuItem.subMenuItems = subMenuItems;

        if(menuItem.rule === "administrator"  || menuItem.rule === "manager") {
          if(this.user.role === "Administrator" || this.user.role === "Manager") {
            this.menuItems.push(menuItem);
          }
        } else {
          this.menuItems.push(menuItem);
        }
      }
    },
  },

  watch: {
    drawer: function() {
      if (!this.drawer) {
        store.commit("toggleDrawer");
      }
    },

    "$store.state.drawerOpen": function() {
      this.drawer = this.$store.getters.getDrawerOpen;
    },

    "$store.state.user": function() {
      this.user = this.$store.getters.getUser;
      this.filterMenuItems();
    },

    "$vuetify.breakpoint.name": function() {
      this.breakpoint = this.getBreakPoint();
      if (this.breakpoint !== this.lastBreak && this.breakpoint > 2) {
        this.lastBreak = this.breakpoint;
        if (this.drawer) {
          store.commit("toggleDrawer");
        }
      } else if (this.breakpoint < 3) {
        this.lastBreak = this.breakpoint;
      }
    },
  },

  store,
};
</script>

<style lang="scss" scoped>
.v-icon { 
  padding-right: 10px;
}
</style>
