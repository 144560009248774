import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/_plugins/vuetify';
import router from '@/_router/index'
import '../src/assets/styles/main.scss'
import 'video.js/dist/video-js.css'
import VueGtag from "vue-gtag";

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "UA-180685785-1" }
}, router);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
