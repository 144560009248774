<template>
  <strong>
    <v-btn text @click="onLocation">{{city}}</v-btn>
    <br />
    <v-btn text :href='telephoneRef' v-if='phone.length > 0' @click='onPhone'>
      <div :class="theme + '-accent'">{{ phone }}</div>
    </v-btn>
  </strong>
</template>

<script>
import router from '@/_router/index'

export default {
  props: ["to", "city", "phone"],
  name: "location-ctrl",

  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  data: () => ({
    telephoneRef: '',
  }),

  methods: {
      onLocation() {
          console.log('ref: ', this.telephoneRef)
          router.push(this.to)
      },

      onPhone() {
          // router.push(this.telephoneRef)
          console.log('Phone: ', this.phone)
      }
  },

  mounted() {
      this.telephoneRef = 'tel:/' + this.phone
  }
};
</script>

<style lang="scss" scoped>
.location {
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  list-style-type: none;
}

</style>
