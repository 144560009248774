<template>
  <v-app-bar app class="app-bar" :hide-on-scroll="breakpoint < 3" prominent>
    <v-app-bar-nav-icon
      @click="onMenu"
      v-if="breakpoint > 2"
    ></v-app-bar-nav-icon>
    <div>
      <a @click="onHome">
        <v-img
          class="logo"
          src="@/assets/logo-min.jpg"
          height="103"
          width="318"
          min-height="103"
          min-width="318"
        >
        </v-img>
      </a>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12" xl="12" lg="12" md="0" sm="0">
          <v-row>
            <v-spacer></v-spacer>
            <social-buttons v-if="breakpoint > 2"></social-buttons>
            <v-spacer></v-spacer>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <div v-if="breakpoint == 2" class="contact">
              <v-btn text to="/contact">
                <v-icon x-large>mdi-email</v-icon>
                <div class="contact-text">Contact Us</div>
              </v-btn>
            </div>
            <div v-for="item in menuItems" v-bind:key="item.id">
              <div v-if="breakpoint > item.breakpoint" class="contact">
                <v-menu offset-y v-if="item.subMenuItems != 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text>
                      <v-icon>{{ item.icon }}</v-icon
                      >{{ item.text }}
                    </v-btn>
                  </template>
                  <v-list
                    v-for="subItem in item.subMenuItems"
                    v-bind:key="subItem.id"
                  >
                    <v-list-item :to="subItem.to">
                      <v-list-item-title>
                        <v-icon>{{ subItem.icon }}</v-icon
                        >{{ subItem.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn text :to="item.to" v-else>
                  <v-icon>{{ item.icon }}</v-icon
                  >{{ item.text }}
                </v-btn>
              </div>
            </div>
            <v-spacer></v-spacer>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import menuService from "@/_services/menu-service.js";
import store from "@/_store/index";
import router from "@/_router/index";
import breakpointMixin from "../../mixins/breakpoint-mixin";
import social from "@/components/app/social-buttons";

export default {
  name: "app-bar",

  components: {
    "social-buttons": social,
  },

  data: () => ({
    title: "",
    menuItems: [],
  }),

  mixins: [breakpointMixin],

  mounted() {
    this.menuItems = menuService.getMenu();
  },

  methods: {
    onHome() {
      router.push("/");
    },

    onLogin() {},

    onMenu() {
      store.commit("toggleDrawer");
    },

    onMenuItem(to) {
      router.push(to);
    },
  },

  store: store,
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
.app-bar {
  padding-bottom: 10px;
}
.bbb {
  margin-top: 10px;
}
.contact {
  padding-top: 18px;
}
.contact-text {
  font-size: 2rem;
}

.logo {
  margin-top: 9px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.menu {
  margin-top: 10px;
}
.moto {
  text-align: center;
  font-size: 30px;
  color: red;
}
.v-icon {
  padding-right: 10px;
}
</style>
